import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { PortalClient } from '../../core/portal-client';
import { Skeleton } from '@mui/material';

interface Data {
  name: string;
  supply: string;
  pool1: string;
  pool2: string;
  pool1USD: number;
  pool2USD: number;
  volume24h: any;
  volume7d: any;
}

function createData(
  name: string,
  supply: string,
  pool1: string,
  pool2: string,
  pool1USD: number,
  pool2USD: number,
  volume24h: any,
  volume7d: any,
): Data {
  return {
    name,
    supply,
    pool1,
    pool2,
    pool1USD,
    pool2USD,
    volume24h,
    volume7d,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function formatNumber(num: any, precision?: number) {
  if (typeof num === 'string') {
    let amt = Number(num.split(' ')[0]);
    if (precision) {
      amt = Number(amt.toFixed(precision));
    }
    const amtStr = amt.toLocaleString('en-GB');
    return `${amtStr} ${num.split(' ')[1]}`;
  }
  if (typeof num === 'number') {
    let amt = num;
    if (precision) {
      amt = Number(amt.toFixed(precision));
    }
    const amtStr = amt.toLocaleString('en-GB');
    return `${amtStr}`;
  }
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Pool',
  },
  {
    id: 'supply',
    numeric: true,
    disablePadding: false,
    label: 'Supply',
  },
  {
    id: 'pool1',
    numeric: true,
    disablePadding: false,
    label: 'Pool 1',
  },
  {
    id: 'pool1USD',
    numeric: true,
    disablePadding: false,
    label: 'Pool 1 (USD)',
  },
  {
    id: 'pool2',
    numeric: true,
    disablePadding: false,
    label: 'Pool 2',
  },
  {
    id: 'pool1USD',
    numeric: true,
    disablePadding: false,
    label: 'Pool 2 (USD)',
  },
  {
    id: 'volume24h',
    numeric: true,
    disablePadding: false,
    label: 'Volume 24h',
  },
  {
    id: 'volume7d',
    numeric: true,
    disablePadding: false,
    label: 'Volume 7d',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Pools
        </Typography>
    </Toolbar>
  );
}

export default function PoolsTable({ history} : any) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState<any[]>([]);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const safeValue = (history: any, key: string, token: string ) => {
    console.log({
      history,
      key,
      token,
    })
    if (!history) return <Skeleton style={{float: 'right', width: 50}} />;
    if (!history[key]) return <Skeleton style={{float: 'right', width: 50}} />;
    if (!history[key][token]) return <Skeleton style={{float: 'right', width: 50}} />;
    return `$${formatNumber(history[key][token],2)}`;
  }

  const loadData = async() => {
    const client = new PortalClient();
    const pools = await client.fetchPools();
    const data = [];
    for (const pool of pools) {
      data.push(
        createData(pool.name, pool.supply, pool.pool1, pool.pool2, pool.pool1USD, pool.pool2USD, safeValue(history, 'stats24h', pool.name.toUpperCase()), safeValue(history, 'stats7d', pool.name.toUpperCase()) ),
      )
    }
    setRows(data);
  }

  React.useEffect(() => {
    loadData()
  }, [history])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar />
          {
              (!rows || rows.length === 0) && (
                <div style={{padding: 10}}>
                  <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
                  <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
                </div>
              )
            }
            {
              (rows && rows.length > 0) && (
                <>
                <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
      
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row.name}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="normal"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{formatNumber(row.supply)}</TableCell>
                            <TableCell align="right">{formatNumber(row.pool1)}</TableCell>
                            <TableCell align="right">${formatNumber(row.pool1USD)}</TableCell>
                            <TableCell align="right">{formatNumber(row.pool2)}</TableCell>
                            <TableCell align="right">${formatNumber(row.pool2USD)}</TableCell>
                            <TableCell align="right">{row.volume24h}</TableCell>
                            <TableCell align="right">{row.volume7d}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height:  53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              </>
              )
            }
      </Paper>
    </Box>
  );
}