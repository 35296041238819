import axios from "axios";

//@ts-ignore
import { DateTime } from "luxon";

export interface ITokenData {
    icon: string;
    name: string;
    price: number;
}

export class PortalClient {
    private BASE_URL = `https://dashboard-api.libre.org`;
    private NODE_URL = `https://lb.libre.org`;
    getTokenIcon = (token: string) => {
        if (token.toLowerCase() === 'libre') return `https://dashboard.libre.org/icons/libre-asset-icon.svg`;
        if (token.toLowerCase() === 'pbtc') return `https://dashboard.libre.org/icons/btc-asset-icon.svg`;
        if (token.toLowerCase() === 'pusdt') return `https://dashboard.libre.org/icons/usdt-asset-icon.png`;
    }
    fetchTokens = async() => {
        const tokenStats: any[] = [];
        const response = await axios.get(`${this.BASE_URL}/tokens`);
        for (const token of response.data) {
            tokenStats.push({
                    icon: this.getTokenIcon(token.name),
                    ...token,
                }
            )
        }
        return tokenStats;
    }

    fetchExchangeRates = async() => {
        const response = await axios.get(`${this.BASE_URL}/exchange-rates`);
        return response.data;
    }

    fetchTickers = async() => {
        const response = await axios.get(`${this.BASE_URL}/tickers`);
        return response.data;
    }

    convertToUSD = (quantity: string, rates: any) => {
        const amount = Number(quantity.split(' ')[0]);
        const currency = quantity.split(' ')[1];
        return parseFloat(Number(amount * rates[currency.toUpperCase()]).toFixed(6));
    }

    getSwapType = (memo: string, amount: string) => {
        const symbol = amount.split(' ')[1].toLowerCase();
        if (!memo) return 'Transaction';
        if (memo.indexOf('BTCUSD') > -1) {
            if (symbol === 'pbtc') {
                return 'Trade BTC to USD';
            } else {
                return 'Trade USD to BTC';
            }
        }
        if (memo.indexOf('BTCLIB') > -1) {
            if (symbol === 'pbtc') {
                return 'Trade BTC to LIBRE';
            } else {
                return 'Trade LIBRE to BTC';
            }
        }
        return 'Trade';
    }

    maskTransaction = (txId: string) => {
        const first6 = txId.substring(0, 6);
        const last6 = txId.substring(txId.length - 6);
        return `${first6}...${last6}`
    }
 
    fetchAndFormatTransactions = async() => {
        const exchangeRates = await this.fetchExchangeRates();
        const url = `${this.NODE_URL}/v2/history/get_actions?limit=1000&account=swap.libre&sort=desc&simple=true`;
        const result = [];
        const data = await axios.get(url);
        for (let transaction of data.data.simple_actions) {
            if (transaction.data && transaction.data.memo) {
                if (transaction.data.memo.indexOf('BTCLIB') > -1 || transaction.data.memo.indexOf('BTCUSD') > -1) {
                    if (transaction.data.quantity && transaction.data.memo) {
                        result.push({
                            type: this.getSwapType(transaction.data.memo, transaction.data.quantity),
                            maskedTxId: this.maskTransaction(transaction.transaction_id),
                            txId: transaction.transaction_id,
                            amount: transaction.data.quantity,
                            usdAmount: this.convertToUSD(transaction.data.quantity, exchangeRates),
                            account: `${transaction.data.from}${transaction.data.to}`.replace('swap.libre', ''),
                            timestamp: transaction.timestamp.substring(0, transaction.timestamp.length -4).replace('T', ' '),
                        })
                    }
                }
            }
        }
        return result;
    }

    fetchHistoricalValues = async() => {
        const exchangeRates = await this.fetchExchangeRates();
        let stats24h = (await axios.get(`${this.BASE_URL}/volumes/24h`)).data;
        let stats7d = (await axios.get(`${this.BASE_URL}/volumes/7d`)).data;
        let tickers = (await axios.get(`${this.BASE_URL}/tickers`)).data;
        const BTCUSD24H = tickers.find((c: any)=>c.ticker_id === 'BTC_USDT');
        const BTCLIB24H = tickers.find((c: any)=>c.ticker_id === 'BTC_LIBRE');
        const BTCUSD24HVol = BTCUSD24H.total_volume;


        const BTCLIB24HVol = BTCLIB24H.total_volume;

        console.log(`STUFF: ${BTCUSD24HVol} ${BTCUSD24H.base_volume} ${BTCUSD24H.target_volume}`)
        stats24h = {
            PBTC: this.convertToUSD(`${stats24h.PBTC} PBTC`, exchangeRates),
            LIBRE: this.convertToUSD(`${stats24h.LIBRE} LIBRE`, exchangeRates),
            PUSDT: this.convertToUSD(`${stats24h.PUSDT} PUSDT`, exchangeRates),
            BTCLIB: BTCLIB24HVol,
            BTCUSD: BTCUSD24HVol,
        }
        stats7d = {
            PBTC: this.convertToUSD(`${stats7d.PBTC} PBTC`, exchangeRates),
            LIBRE: this.convertToUSD(`${stats7d.LIBRE} LIBRE`, exchangeRates),
            PUSDT: this.convertToUSD(`${stats7d.PUSDT} PUSDT`, exchangeRates),
            BTCLIB: stats7d.BTCLIB,
            BTCUSD: stats7d.BTCUSD,
        }
        return {
            stats24h,
            stats7d,
        }
    }

    fetchPools = async() => {
        const exchangeRates = await this.fetchExchangeRates();
        const pools = ['BTCLIB', 'BTCUSD'];
        const result = [];
        for (const pool of pools) {
            const poolInfo = await axios.post(`${this.NODE_URL}/v1/chain/get_table_rows`, {
                code: 'swap.libre',
                table: 'stat',
                scope: pool,
                json: true
            });
            if (poolInfo && poolInfo.data) {
                const poolData = poolInfo.data.rows[0];
                const pool1Q = `${Number(poolData.pool1.quantity.split(' ')[0]).toFixed(4)} ${poolData.pool1.quantity.split(' ')[1]}`;
                const pool2Q = `${Number(poolData.pool2.quantity.split(' ')[0]).toFixed(4)} ${poolData.pool2.quantity.split(' ')[1]}`;
                result.push({
                    name: pool,
                    supply: poolData.supply,
                    pool1: pool1Q,
                    pool2: pool2Q,
                    pool1USD: this.convertToUSD(poolData.pool1.quantity, exchangeRates),
                    pool2USD: this.convertToUSD(poolData.pool2.quantity, exchangeRates),
                    volume7d: 0,
                    volume24h: 0,
                });
            }
        }
        return result;
    }

}