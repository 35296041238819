import { Container, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom"
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LibreDescription, PBTCDescription, USDTDescription } from "./components/token-descriptions/descriptions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{maxWidth: '80%'}}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


export function TokenPage() {
    const { token } = useParams();
    
    const values = ['LIBRE', 'PBTC', 'PUSDT'];
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
        if (token?.toLowerCase() === 'libre') {
            setValue(0);
        }
        if (token?.toLowerCase() === 'pbtc') {
            setValue(1);
        }
        if (token?.toLowerCase() === 'pusdt') {
            setValue(2);
        }
    }, []);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      window.history.replaceState(null, '', `/${values[newValue]}`)
    };

    return  (
        <Container maxWidth="lg">
            <Grid style={{marginTop: 50}}>

                <Box
                    sx={{ flexGrow: 1, display: 'flex', height: 224 }}
                >
                    <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label=""
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="LIBRE" value={0}/>
                        <Tab label="PBTC"  value={1}/>
                        <Tab label="PUSDT"  value={2}/>
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <LibreDescription />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PBTCDescription />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <USDTDescription />
                    </TabPanel>
                    
                </Box>

            </Grid>
        </Container>
    )
}