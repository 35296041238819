import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { PortalClient } from '../../core/portal-client';
import { Skeleton } from '@mui/material';

interface Data {
  type: string;
  maskedTxId: string;
  txId: string;
  amount: number;
  usdAmount: number;
  account: string;
  timestamp: string;
}

function createData(
    type: string,
    txId: string,
    maskedTxId: string,
    amount: number,
    usdAmount: number,
    account: string,
    timestamp: string,  
): Data {
  return {
    type,
    txId, 
    maskedTxId,
    amount,
    usdAmount,
    account,
    timestamp,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'txId',
    numeric: true,
    disablePadding: false,
    label: 'Transaction',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'usdAmount',
    numeric: true,
    disablePadding: false,
    label: 'USD Amount',
  },
  {
    id: 'account',
    numeric: true,
    disablePadding: false,
    label: 'Account',
  },
  {
    id: 'timestamp',
    numeric: true,
    disablePadding: false,
    label: 'Timestamp',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Last Transactions
        </Typography>
    </Toolbar>
  );
}

export default function TransactionsTable() {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('timestamp');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState<any[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const loadData = async() => {
    const client = new PortalClient();
    const result: any[] = [];
    const data = await client.fetchAndFormatTransactions();
    for (const item of data) {
      result.push(
        createData(item.type!, item.txId, item.maskedTxId, item.amount, item.usdAmount, item.account, item.timestamp)
      )
    }
    setRows(result);
  }
  React.useEffect(() => {
    loadData();
  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar />
        {
          (!rows || rows.length === 0) && (
            <div style={{padding: 10}}>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginTop: 10}}/>
          </div>
          )
        }
        {
          rows && rows.length > 0 && (
            <>

<TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.txId}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        padding="normal"
                      >
                        {row.type}
                      </TableCell>
                      <TableCell align="right">
                        <a href={`https://lb.libre.org/v2/explore/transaction/${row.txId}`} target="_blank" style={{color: '#fff'}}>{row.maskedTxId}</a></TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                      <TableCell align="right">{row.usdAmount}</TableCell>
                      <TableCell align="right">{row.account}</TableCell>
                      <TableCell align="right">{row.timestamp}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height:  53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
            </>
          )
        }
      </Paper>
    </Box>
  );
}