import React from 'react';
import logo from './assets/logo-full.svg'
import './App.css';
import { ThemeProvider } from '@emotion/react';
import { AppBar, Button, createTheme, CssBaseline, Grid, Toolbar } from '@mui/material';
import { Homepage } from './pages/home';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { TokenPage } from './pages/token';

function App() {
    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      },
    });
    const styles = ({
      logo: {
        height: 30,
      },
      header: {
        background: 'rgb(25, 27, 31)',
        padding: 10,
      }
    });
  return (
        <ThemeProvider theme={darkTheme}>
          <BrowserRouter>
          
              <CssBaseline />
              <AppBar position="static" style={styles.header}>
                  <Toolbar>
                      <Grid component="div" sx={{ flexGrow: 1, background: 'transparent' }}>
                          <Link to={'/'}><img src={logo} style={styles.logo} /></Link>
                      </Grid>
                  </Toolbar>
              </AppBar>
              <Routes>
                <Route path={'/'} element={<Homepage />}/>
                <Route path={'/:token'} element={<TokenPage />}/>
                </Routes>
            </BrowserRouter>
            
        </ThemeProvider>
  );
}

export default App;
