import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { PortalClient } from "../core/portal-client";
import PoolsTable from "./components/pools-table";
import TokensTable from "./components/tokens-table";
import TransactionsTable from "./components/transactions-table";

export function Homepage() {
    const [history, setHistory] = useState<any>();

    useEffect(() => {
        const client = new PortalClient();
        client.fetchHistoricalValues().then(result => {
            setHistory(result)
        });
    }, []);

    return  (
        <Container maxWidth="lg">
            <Grid style={{marginTop: 20}}>
                <TokensTable history={history}/>
                <PoolsTable history={history}/>
                <TransactionsTable />
            </Grid>
        </Container>
    )
}