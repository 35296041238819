import { Divider, Typography } from "@mui/material"

export function LibreDescription() {
    return (
        <div className="tokenContent">
            <Typography variant="h4">LIBRE</Typography>
            <Divider />
            <p>
            Libre is the governance coin for the Libre blockchain.
            </p>
            <p>
            LIBRE must be “staked” to engage in governance - voting for validators and voting for DAO proposals. In exchange for securing the network, LIBRE holders receive staking rewards in LIBRE. These staking rewards begin high and decrease over time.
            </p>
            <p>
            You choose the amount of LIBRE to stake and the number of days your LIBRE will be staked. <br/>
            The earlier you stake and the longer you stake, the higher your yield. You must stake for at least 1 day - can stake up to 4 years.
            </p>
            <p>
            If you want to claim your LIBRE before the Payout Date is reached, you can Emergency Unstake for a 20% loss on your principal and you will not earn any of the accrued interest. 
            <br/>
            100% of Mint Rush LIBRE was staked on day 30 for a minimum of 30 days.
            </p>
            <p>
            Staking LIBRE can be done via https://dashboard.libre.org or by sending LIBRE using any wallet to stake.libre with the memo “stakefor:DAYS” - for example, if you want to stake 10,000 LIBRE for 365 days, you would send 10000.0000 LIBRE with a memo of “stakefor:365.”
            </p>
            <p className="tokenDescription">
                <span>Launch Date:</span> 7/4/2022 <br/>
                <span>Crypto: </span> secp256k1  <br/>
                <span>Consensus:</span> DPOS  <br/>
                <span>Total wallets:</span> 420K+  <br/>
                <span>Block Time:</span> 0.5 sec  <br/>
                <span>Block Reward:</span>  4 LIBRE  <br/>
                <span>Block Reward halving schedule:</span> 6 months  <br/>
                <span>Max Coin Supply:</span> 100B  <br/>
                <span>Founders Reward:</span> No  <br/>
                <span>ICO:</span> No  <br/>
            </p>
        </div>
    )
}

export function USDTDescription() {
    return (
        <div className="tokenContent">
            <Typography variant="h4">PUSDT</Typography>
            <Divider />
            <p>
                Pegged-USDT is provided by p.Network decentralized nodes. USDT is custodied by p.Network nodes who issue PUSDT on the Libre blockchain. 
            </p>
            <p className="tokenDescription">
                <span>Contract:</span> <a href="https://lb.libre.org/v2/explore/account/usdt.ptokens" target={'_blank'}>usdt.ptokens</a>  <br/>
                <span>Symbol:</span> PUSDT  <br/>
                <span>Precision:</span> 9  <br/>
            </p>
        </div>
    )
}

export function PBTCDescription() {
    return (
        <div className="tokenContent">
            <Typography variant="h4">PBTC</Typography>
            <Divider />
            <p>
            Pegged-Bitcoin is provided by p.Network decentralized nodes. BTC is custodied by p.Network nodes who issue PBTC on the Libre blockchain.
            </p>
            <p className="tokenDescription">
                <span>Contract:</span> <a href="https://lb.libre.org/v2/explore/account/usdt.ptokens" target={'_blank'}>btc.ptokens</a>  <br/>
                <span>Symbol:</span> PBTC  <br/>
                <span>Precision:</span> 9  <br/>
            </p>
        </div>
    )
}